import * as Types from '@haaretz/s-fragments/Types';

export type AssetFragment = (
  { id: string, name?: string | null, type: string, value: number, changePercentage: number }
  & { __typename: 'Asset' }
);


export type AssetFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const AssetFragmentGql = `
    fragment AssetFragment on Asset {
  id
  name
  type
  value
  name
  changePercentage
  __typename
}
    `;