import fork from '@haaretz/l-fork.macro';

export default function emailValidator(email: string): {
  valid: boolean;
  text: string;
} {
  // istanbul ignore else
  if (!email) {
    return { valid: false, text: fork({ default: 'אנא הזינו אימייל', hdc: 'Enter email' }) };
  }

  // istanbul ignore else
  if (!/\S+@\S+\.\S+/.test(email)) {
    return {
      valid: false,
      text: fork({ default: 'יש להזין אימייל תקין', hdc: 'Use a valid email format' }),
    };
  }

  // istanbul ignore else
  if (email.length > 40) {
    return {
      valid: false,
      text: fork({
        default: 'יש להזין אימייל באורך של עד 40 תווים',
        hdc: 'Email must contain less than 40 characters',
      }),
    };
  }

  return { valid: true, text: fork({ default: 'אנא הזינו אימייל', hdc: 'Enter email' }) };
}
