'use client';

import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import { useParagraphTickerQuery } from '@haaretz/s-queries/ParagraphTicker';
import React from 'react';
import s9 from 'style9';

import type { AssetFragment } from '@haaretz/s-fragments/Asset';

const c = s9.create({
  stk: {
    fontStyle: 'inherit',
    direction: 'ltr',
    display: 'inline-block',
    paddingInlineEnd: space(0.25),
  },
  percent: {
    fontWeight: 700,
  },
  positive: {
    color: color('quaternary800'),
  },
  negative: {
    color: color('secondary900'),
  },
});

interface StockProps {
  bodyRef: React.RefObject<HTMLDivElement>;
}

function renderStk(asset: AssetFragment) {
  const container = document.createElement('i');

  container.classList.add(...s9(c.stk).split(' '));

  const isPositive = (asset.changePercentage ?? 0) >= 0;

  const percentageElement = document.createElement('span');

  percentageElement.classList.add(
    ...s9(c.percent, isPositive ? c.positive : c.negative).split(' ')
  );

  percentageElement.innerHTML = `${isPositive ? '+' : ''}${asset.changePercentage}${'%'}`;

  container.appendChild(document.createTextNode('\u00A0('));
  container.appendChild(percentageElement);
  container.appendChild(document.createTextNode(`\u00A0${asset.value})\u00A0`));

  return container.outerHTML;
}

function Stock({ bodyRef }: Readonly<StockProps>) {
  const [stks, setStks] = React.useState<{ [x: string]: Element } | null>(null);

  const { data } = useParagraphTickerQuery(
    { ids: Object.keys(stks ?? {}) },
    { enabled: stks !== null }
  );

  React.useEffect(() => {
    const { current } = bodyRef;

    if (current) {
      const STKs = Array.from(current.querySelectorAll('span[data-stk]'));

      if (STKs.length) {
        const allStks: typeof stks = {};

        for (const item of STKs) {
          if (item instanceof HTMLElement && item.dataset.stk) {
            allStks[item.dataset.stk] = item;
          }
        }

        setStks(allStks);
      }
    }
  }, [bodyRef]);

  React.useEffect(() => {
    if (!data?.assets) return;

    for (const asset of data.assets) {
      const element = stks?.[asset.id];

      if (element) {
        const text = renderStk({ ...asset });
        element.innerHTML = text;
      }
    }
  }, [data?.assets, stks]);

  return null;
}

export default function StockWrapper({ bodyRef }: Readonly<StockProps>) {
  return (
    <React.Suspense fallback={null}>
      <Stock bodyRef={bodyRef} />
    </React.Suspense>
  );
}
