'use client';
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import useUser from '@haaretz/s-atoms/user';
import ClickArea from '@haaretz/s-click-area';
import FeatureBlockModal from '@haaretz/s-feature-block-modal';
import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import React from 'react';
import s9 from 'style9';

import type { ActionBtnProps } from '../../types';

// `c` is short for `classNames`
const c = s9.create({
  hideOnMobileDevices: {
    ...mq({ until: 'l', value: { display: 'none' } }),
  },
});

export default function PrintBtn({ clickAreaStyleExtend = [], iconStyleExtend }: ActionBtnProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const user = useUser('cookieValue');
  const isPayingUser = user.userType === 'paying';

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <ClickArea
        styleExtend={[...clickAreaStyleExtend, c.hideOnMobileDevices]}
        rippleSize="small"
        onClick={isPayingUser ? window.print : openModal}
        data-testid="print-btn"
        title={fork({ default: 'הדפסת כתבה', hdc: 'Print article' })}
      >
        <Icon icon="printer" styleExtend={iconStyleExtend} variant="neutral" />
        <VisuallyHidden>{fork({ default: 'הדפסת כתבה', hdc: 'Print article' })}</VisuallyHidden>
      </ClickArea>
      <FeatureBlockModal type="print" isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
}
