'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import ErrorBoundary from '@haaretz/s-error-boundary';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { BaseArticleFragment } from '@haaretz/s-fragments/articles/Base.article';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    backgroundColor: color('neutral200'),
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
  },
  pullDown: {
    marginBottom: space(-4),
  },
  title: {
    fontWeight: 700,
    ...typesetter(1),

    ...mq({ from: 'xl', value: { ...typesetter(0) } }),
  },
  closeBtn: {
    backgroundColor: color('tldrBtn'),
    fontSize: space(5.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: space(11),
    aspectRatio: '1',

    ':focus': {
      backgroundColor: color('tldrBtnActive'),
    },
    ':active': {
      backgroundColor: color('tldrBtnActive'),
    },
    ...mq({ from: 'xxl', value: { fontSize: space(6), height: space(12) } }),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: space(2),
    width: '100%',
    cursor: 'pointer',
  },
  description: {
    paddingTop: space(2),
    paddingRight: space(3),
    paddingLeft: space(3),
    paddingBottom: space(3),

    ...merge(
      // padding
      mq({ until: 's', value: { paddingTop: space(1), paddingBottom: space(2) } }),
      mq({ from: 'l', until: 'xl', value: { paddingTop: space(2.5), paddingBottom: space(4.5) } }),
      mq({
        from: 'l',
        value: {
          paddingRight: space(4),
          paddingLeft: space(4),
          paddingBottom: space(4),
        },
      })
    ),
  },
  paywall: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: space(3),
    paddingBottom: space(4),
    paddingRight: space(4),
    paddingLeft: space(4),
    ...typesetter(1),

    ...merge(
      // typesetter
      mq({ from: 'xl', value: { ...typesetter(0) } }),

      // padding
      mq({
        until: 's',
        value: {
          paddingTop: space(2),
          paddingBottom: space(3),
          paddingRight: space(3),
          paddingLeft: space(3),
        },
      })
    ),
  },
  icon: {
    transitionDuration: '200ms',
    transitionTimingFunction: 'ease-in-out',
    transitionProperty: 'transform',
  },
  iconIsOpen: {
    transform: 'rotate(0)',
  },
  iconIsClosed: {
    transform: 'rotate(-45deg)',
  },
  subscription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  purchaseBtn: {
    marginTop: space(3),
    alignSelf: 'center',
  },
});

const purchaseBtnText = 'כן, אני רוצה';

interface TldrClientProps {
  /** The Children to be rendered inside `<TldrClient>` */
  children?: React.ReactNode;
  wordCount: BaseArticleFragment['tldrWordCount'];
  pullDown: boolean;
}

function TldrClient({ wordCount, children, pullDown }: TldrClientProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const user = useUser('cookieValue');
  const [isPayingUser, setIsPayingUser] = React.useState(false);
  const tldrRef = React.useRef(null);
  const paywallRef = React.useRef(null);
  const biAction = useBi();

  React.useEffect(() => {
    setIsPayingUser(user.userType === 'paying');
  }, [user.userType]);

  const payingUserTitle =
    (wordCount || 0) > 0 ? `תקציר הכתבה ב-${wordCount} מילים` : 'תקציר הכתבה עד 100 מילים';
  const title = isPayingUser ? payingUserTitle : 'רוצה לקרוא תקציר של הכתבה?';

  const BI_BASE_DATA = {
    feature: 'TLDR',
    feature_type: 'Content',
    campaign_name: 'TLDR',
    campaign_details: title,
  };

  const content = isPayingUser ? (
    <div className={s9(c.description)}>{children}</div>
  ) : (
    <div className={s9(c.paywall)} ref={paywallRef}>
      רכשו מינוי דיגיטלי ותיהנו מקריאה ללא הגבלה ושירותים הזמינים למנויים בלבד
      <Button
        as="a"
        href={fork({
          default:
            'https://promotion.haaretz.co.il/?htm_source=site&htm_medium=button&htm_campaign=TLDR&htm_content=subscribe',
          tm: 'https://promotion.themarker.com/?htm_source=site&htm_medium=button&htm_campaign=TLDR&htm_content=subscribe',
        })}
        variant="sales"
        styleExtend={[c.purchaseBtn]}
        onClick={() =>
          biAction({
            ...BI_BASE_DATA,
            campaign_details: purchaseBtnText,
            action_id: 3,
          })
        }
      >
        {purchaseBtnText}
      </Button>
    </div>
  );

  useImpressionObserver({
    elementRef: tldrRef,
    biData: BI_BASE_DATA,
  });

  useImpressionObserver({
    elementRef: paywallRef,
    biData: {
      ...BI_BASE_DATA,
      campaign_details: purchaseBtnText,
    },
  });

  const onClick = () => {
    if (!isOpen) {
      biAction({
        ...BI_BASE_DATA,
        action_id: 162,
      });
    }

    setIsOpen(prev => !prev);
  };

  return (
    <aside className={`${s9(c.base, pullDown && c.pullDown)} no-print`} ref={tldrRef}>
      {/* we do this because there is a button inside the div for accessibility and we need both the div and and the button to be clickable */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={s9(c.header)} onClick={onClick}>
        <button className={s9(c.closeBtn)}>
          <Icon
            icon="plus"
            styleExtend={[c.icon, isOpen ? c.iconIsClosed : c.iconIsOpen]}
            inlineStyle={{ '--icon-color-override': color('tldrIcon') }}
          />
          <VisuallyHidden>{fork({ default: 'הרחבה', hdc: 'expand' })}</VisuallyHidden>
        </button>
        <h3 className={s9(c.title)}>{title}</h3>
      </div>
      {isOpen ? content : null}
    </aside>
  );
}

export default function TldrClientWrapper(props: TldrClientProps) {
  return (
    <ErrorBoundary>
      <TldrClient {...props} />
    </ErrorBoundary>
  );
}
