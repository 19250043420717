import * as Types from '@haaretz/s-fragments/Types';

import { AssetFragmentGql } from '@haaretz/s-fragments/Asset';
import { AssetFragment } from '@haaretz/s-fragments/Asset';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type ParagraphTickerQueryVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type ParagraphTickerQuery = (
  { assets?: Array<(
    AssetFragment
    & { __typename: 'Asset' }
  )> | null }
  & { __typename: 'Query' }
);


export const ParagraphTickerQueryDocument = `
    query ParagraphTickerQuery($ids: [String!]!) {
  assets(ids: $ids) {
    ...AssetFragment
  }
}
    ${AssetFragmentGql}`;
export const useParagraphTickerQuery = <
      TData = ParagraphTickerQuery,
      TError = Error
    >(
      variables: ParagraphTickerQueryVariables,
      options?: UseQueryOptions<ParagraphTickerQuery, TError, TData>
    ) =>
    useQuery<ParagraphTickerQuery, TError, TData>(
      ['ParagraphTickerQuery', variables],
      fetch<ParagraphTickerQuery, ParagraphTickerQueryVariables>(ParagraphTickerQueryDocument, variables),
      options
    );
useParagraphTickerQuery.document = ParagraphTickerQueryDocument;


useParagraphTickerQuery.getKey = (variables: ParagraphTickerQueryVariables) => ['ParagraphTickerQuery', variables];
;

export const useInfiniteParagraphTickerQuery = <
      TData = ParagraphTickerQuery,
      TError = Error
    >(
      pageParamKey: keyof ParagraphTickerQueryVariables,
      variables: ParagraphTickerQueryVariables,
      options?: UseInfiniteQueryOptions<ParagraphTickerQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<ParagraphTickerQuery, TError, TData>(
      ['ParagraphTickerQuery.infinite', variables],
      (metaData) => fetch<ParagraphTickerQuery, ParagraphTickerQueryVariables>(ParagraphTickerQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteParagraphTickerQuery.getKey = (variables: ParagraphTickerQueryVariables) => ['ParagraphTickerQuery.infinite', variables];
;

useParagraphTickerQuery.fetcher = (variables: ParagraphTickerQueryVariables, options?: RequestInit['headers']) => fetch<ParagraphTickerQuery, ParagraphTickerQueryVariables>(ParagraphTickerQueryDocument, variables, options);