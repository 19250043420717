import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type GenerateGiftTokenPersonalMutationVariables = Types.Exact<{
  input: Types.GenerateGiftTokenInput;
}>;


export type GenerateGiftTokenPersonalMutation = (
  { generateGiftToken: (
    { error?: string | null, data?: (
      { count: number, limit: number, status: Types.GiftStatus, token?: string | null }
      & { __typename: 'GenerateGiftTokenDataType' }
    ) | null }
    & { __typename: 'GenerateGiftTokenType' }
  ) }
  & { __typename: 'Mutation' }
);


export const GenerateGiftTokenPersonalMutationDocument = `
    mutation GenerateGiftTokenPersonalMutation($input: GenerateGiftTokenInput!) {
  generateGiftToken(input: $input) {
    __typename
    data {
      __typename
      count
      limit
      status
      token
    }
    error
  }
}
    `;
export const useGenerateGiftTokenPersonalMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateGiftTokenPersonalMutation, TError, GenerateGiftTokenPersonalMutationVariables, TContext>) =>
    useMutation<GenerateGiftTokenPersonalMutation, TError, GenerateGiftTokenPersonalMutationVariables, TContext>(
      ['GenerateGiftTokenPersonalMutation'],
      (variables?: GenerateGiftTokenPersonalMutationVariables) => fetch<GenerateGiftTokenPersonalMutation, GenerateGiftTokenPersonalMutationVariables>(GenerateGiftTokenPersonalMutationDocument, variables)(),
      options
    );
useGenerateGiftTokenPersonalMutation.getKey = () => ['GenerateGiftTokenPersonalMutation'];

useGenerateGiftTokenPersonalMutation.fetcher = (variables: GenerateGiftTokenPersonalMutationVariables, options?: RequestInit['headers']) => fetch<GenerateGiftTokenPersonalMutation, GenerateGiftTokenPersonalMutationVariables>(GenerateGiftTokenPersonalMutationDocument, variables, options);