'use client';

import SortDropdown from '@haaretz/s-dropdown';

import { useLiveBlogActions } from '../LiveBlogProvider';

import type { LiveBlogItemsSort } from '@haaretz/s-fragments/Types';

const options: { text: string; value: LiveBlogItemsSort }[] = [
  { text: 'Desc', value: 'desc' },
  { text: 'Acc', value: 'asc' },
] as const;

export default function LiveBlogMenu() {
  const { setOrder, setFirstItemId, setLastItemId } = useLiveBlogActions();
  return (
    <div>
      <SortDropdown
        options={options}
        label="Sort by:"
        initialSelectedOption={0}
        onChange={option => {
          setFirstItemId(null);
          setLastItemId(null);
          setOrder(options[option ?? 0].value || 'desc');
        }}
      />
    </div>
  );
}
