'use client';

import fork from '@haaretz/l-fork.macro';
import Button from '@haaretz/s-button';
import throwError from '@haaretz/s-common-utils/throwError';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';

import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import { useLiveBlog } from '../LiveBlogProvider';

function LiveBlogEndItems() {
  const [itemsJSX, setItemsJSX] = React.useState<React.ReactNode>(null);
  const [isPending, startTransition] = React.useTransition();
  const [showMoreItems, setShowMoreItems] = React.useState(false);
  const pathname = usePathname();
  const router = useRouter();
  const { lastItemId, order } = useLiveBlog();

  React.useEffect(() => {
    setItemsJSX([]);
  }, [order]);

  const loadMoreButtonRef = React.useRef<HTMLButtonElement>(null);

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const response = await loadMoreLiveBlogItems({
            lastItemId,
            area: 'bottom',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
          });

          setItemsJSX(response.html);
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [lastItemId, order]
  );

  const loadMoreObserverCb = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const element = entries[0];
      const isFullyInViewPort = element.isIntersecting;

      if (isFullyInViewPort) {
        handleLoadMoreItems();
      }
    },
    [handleLoadMoreItems]
  );

  const observer = useIntersectionObserver({
    rootMargin: '2000px 0px 2000px 0px',
    cb: loadMoreObserverCb,
  });

  React.useEffect(() => {
    const observedElement = loadMoreButtonRef.current;

    if (observedElement && observer) observer.observe(observedElement);

    return () => {
      if (observedElement && observer) observer.unobserve(observedElement);
    };
  }, [observer]);

  return (
    <div>
      {showMoreItems ? itemsJSX : null}
      <Button
        ref={loadMoreButtonRef}
        onClick={() => {
          router.push(pathname, { scroll: false });

          setShowMoreItems(true);
        }}
        {...(isPending
          ? { state: 'busy', busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }) }
          : { state: 'auto' })}
      >
        {fork({ default: 'טענו עוד', hdc: 'Load more' })}
      </Button>
    </div>
  );
}

export default function LiveBlogEndItemsWrapper() {
  const { lastItemId } = useLiveBlog();

  if (!lastItemId) return null;

  return <LiveBlogEndItems />;
}
