'use client';

import throwError from '@haaretz/s-common-utils/throwError';
import LogoLoadingIndicator from '@haaretz/s-logo-loading-indicator';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import { useLiveBlog, useLiveBlogActions } from '../LiveBlogProvider';

interface LiveBlogBaseItemsProps {
  initFirstItemId?: string | null;
  initLastItemId?: string | null;
}

export default function LiveBlogBaseItems({
  children,
  initFirstItemId,
  initLastItemId,
}: React.PropsWithChildren<LiveBlogBaseItemsProps>) {
  const { order } = useLiveBlog();
  const prevOrderRef = React.useRef(order);
  const { setFirstItemId, setLastItemId } = useLiveBlogActions();
  const [itemsJSX, setItemsJSX] = React.useState<React.ReactNode[]>([]);
  const [isPending, startTransition] = React.useTransition();

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const response = await loadMoreLiveBlogItems({
            lastItemId: undefined,
            area: 'bottom',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
            count: 10,
          });

          setItemsJSX(response.html);
          setLastItemId(response.lastItemId);

          if (order === 'desc') {
            setFirstItemId(response.firstItemId);
          }
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [order, setFirstItemId, setLastItemId]
  );

  React.useEffect(() => {
    if (prevOrderRef.current !== order) {
      prevOrderRef.current = order;
      handleLoadMoreItems();
    }
  }, [order, handleLoadMoreItems]);

  useOnce(
    () => {
      setFirstItemId(initFirstItemId);
      setLastItemId(initLastItemId);
    },
    !!(initFirstItemId || initLastItemId)
  );

  if (isPending) return <LogoLoadingIndicator />;

  if (!itemsJSX.length) return children;

  return itemsJSX;
}
