'use client';

import fork from '@haaretz/l-fork.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import { useColorMode } from '@haaretz/s-color-mode';
import ErrorBoundary from '@haaretz/s-error-boundary';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import { usePathname } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import type { Mode } from '@haaretz/s-types';

const c = s9.create({
  base: {
    gridColumnStart: 'main',
    gridColumnEnd: 'main',
  },
});

const BI_BASE_DATA = {
  feature: 'Trinity Text to Speech',
  feature_type: 'Content',
  campaign_name: 'Trinity Text to Speech',
};

function TrinityPlayer() {
  const platform = usePlatform();
  const pathname = usePathname();
  const user = useUser('cookieValue');
  const { colorMode } = useColorMode() || { colorMode: 'light' };
  const biAction = useBi();
  const trinityPlayerRef = React.useRef<HTMLDivElement | null>(null);
  const scriptId = React.useId();

  const isPodcastArticle = pathname.includes('/podcasts/');

  useImpressionObserver({
    elementRef: trinityPlayerRef,
    biData: BI_BASE_DATA,
  });

  React.useEffect(() => {
    const handleTrinityMessage = (event: MessageEvent) => {
      if (!window.TRINITY_PLAYER?.constants) return;

      const isEventReady = !!event.data && !!event.data.value && !!event.data.value.action;
      if (
        isEventReady &&
        (event.data.value.action === 'resumed' || event.data.value.action === 'playClicked')
      ) {
        biAction({
          ...BI_BASE_DATA,
          action_id: 161,
          campaign_details: event.data.value.action,
        });
      }
    };

    window.addEventListener('message', handleTrinityMessage);

    return () => {
      window.removeEventListener('message', handleTrinityMessage);
    };
  }, [biAction]);

  const loadTrinityScript = React.useCallback(() => {
    const element = trinityPlayerRef.current;

    if (!element || !!element.querySelector(`script[id="${scriptId}"]`)) {
      return;
    }
    const isPayingUser = user.userType === 'paying';

    const params = new URLSearchParams({
      pageURL: window.location.href,
      subscriber: isPayingUser ? '1' : '0',
      ...(isDarkMode(colorMode)
        ? {
            isDarkMode: '1',
            themeId: '312',
          }
        : {
            isDarkMode: '0',
          }),
    });

    const src = `https://trinitymedia.ai/player/trinity/${fork({
      default: '2900005985',
      hdc: '2900001646',
    })}/?${params.toString()}`;

    // 'Next' Script loads in either '<head>' (beforeInteractive)
    // or later in '<body>' (afterInteractive or lazyOnLoad).
    // It's not an option here, due to the fact that document.currentScript is used
    // in Trinity's script to attach it to the correct '<script>' html element.

    // Also, it's not working with a '<script>' JSX element (the script isn't loaded at all
    // even though it's in the dom), so we use this as workaround.
    const script = document.createElement('script');
    script.src = src;
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', scriptId);
    script.setAttribute('async', 'true');
    script.setAttribute('data-testid', 'trinity-player');
    trinityPlayerRef?.current?.appendChild(script);
  }, [colorMode, scriptId, user.userType]);

  if ((platform === 'app' && isPodcastArticle) || !user.userType) {
    return null;
  }

  return (
    <div
      ref={ref => {
        trinityPlayerRef.current = ref;

        loadTrinityScript();
      }}
      className={`hide-when-empty ${s9(c.base)}`}
    />
  );
}

function isDarkMode(mode: Mode) {
  if (mode === 'dark') return true;
  if (mode === 'system') return window.matchMedia('(prefers-color-scheme: dark)').matches;
  return false;
}

export default function TrinityPlayerWrapper() {
  return (
    <ErrorBoundary>
      <TrinityPlayer />
    </ErrorBoundary>
  );
}
