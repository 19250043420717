import selectStorage from '../utils/selectStorage';

interface GetItemArgs {
  key: string;
  errorMessage?: string;
  storage: 'local' | 'session';
}

export default function getAndRemoveItem({ key, storage, errorMessage }: GetItemArgs) {
  if (typeof window === 'undefined') return null;

  let item: null | string | string[] = null;

  try {
    const selectedStorage = selectStorage(storage);

    item = selectedStorage.getItem(key);
    selectedStorage.removeItem(key);
  } catch (error) {
    console.error(`${errorMessage ? `${errorMessage}: ` : ''}${(error as Error).message}`);
  }

  return item;
}
