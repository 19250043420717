'use client';

import React from 'react';
import s9 from 'style9';

const c = s9.create({
  pixel: {
    position: 'absolute',
    top: 'var(--pixel-percent)',
    width: '1px',
    height: '1px',
    pointerEvents: 'none',
    userSelect: 'none',
    touchAction: 'none',
  },
});

export default function Pixels() {
  const pixels = Array.from({ length: 5 }, (_, i) => (i + 1) * 20).map(percent => (
    <div className={s9(c.pixel)} style={{ '--pixel-percent': `${percent}%` }} key={percent} />
  ));

  return pixels;
}
