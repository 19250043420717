'use client';

import AlertsSubscriptionPopover from '@haaretz/s-alerts-subscription-popover';
import useUser from '@haaretz/s-atoms/user';
import Chip from '@haaretz/s-chip';
import HtzLink from '@haaretz/s-htz-link';
import { useTagAlertsRegistrationMutation } from '@haaretz/s-mutations/TagAlertsRegistration';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';

import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';

export default function ArticleTag({ name, contentId, url }: TagPageFragment) {
  const user = useUser('cookieValue');
  const tagRef = React.useRef(null);
  const [isRegistered, setIsRegistered] = React.useState<boolean>(false);
  const [isLoggedIn] = React.useState(user.isLoggedIn);
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const biAction = useBi();
  const handleOpenPopoverStateChangeHandler = (boolean: boolean) => {
    setOpenPopover(boolean);
  };

  const biDataForRegisteredUsers = {
    campaign_name: 'follow tag',
    feature: 'TagAlert - article page',
    feature_type: 'Content',
    newsletter_segment_id: contentId,
    newsletter_segment_name: name,
    newsletter_email: user.userMail,
  };

  const tagAlertsMutation = useTagAlertsRegistrationMutation();
  const handleAnonymousOnClick = () => {
    biAction({
      action_id: 91,
      campaign_details: 'open UI',
      campaign_name: 'follow tag',
      feature: 'TagAlert - article page',
      feature_type: 'Content',
      newsletter_segment_id: contentId,
      newsletter_segment_name: name,
    });
  };
  const handleOnClick = async () => {
    if (!isRegistered) {
      tagAlertsMutation.mutate({
        input: {
          isUnsubscribe: false,
          site: 'htz',
          resourceId: contentId,
        },
      });
      setIsRegistered(true);
      biAction({
        action_id: 9,
        campaign_details: 'follow',
        ...biDataForRegisteredUsers,
      });
    }
    // unsubscribe
    if (isRegistered) {
      tagAlertsMutation.mutate({
        input: {
          isUnsubscribe: true,
          site: 'htz',
          resourceId: contentId,
        },
      });
      setIsRegistered(false);
      biAction({
        action_id: 8,
        campaign_details: 'unfollow',
        ...biDataForRegisteredUsers,
      });
    }
  };

  if (!name || !contentId) return null;

  return (
    <>
      <Chip
        as="button"
        isPressable
        isPressed={isRegistered === true}
        onClick={isLoggedIn ? handleOnClick : handleAnonymousOnClick}
        ref={tagRef}
      >
        {name}
      </Chip>
      <VisuallyHidden aria-hidden>
        <HtzLink href={url} tabIndex={-1}>
          {name}
        </HtzLink>
      </VisuallyHidden>

      {isLoggedIn ? null : (
        <AlertsSubscriptionPopover
          setRegistrationSuccess={setIsRegistered}
          isOpenFromParent={openPopover}
          contentId={contentId}
          alertType="tag"
          onToggle={handleOpenPopoverStateChangeHandler}
          refersToRef={tagRef}
          popoverPlacement={'top-start'}
          tagName={name}
        />
      )}
    </>
  );
}
