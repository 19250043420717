'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import usePlatform from '@haaretz/s-atoms/platform';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import VisuallyHidden from '@haaretz/s-visually-hidden';

import type { ActionBarProps, ActionBtnProps } from '../../types';

interface WhatsappBtnProps extends ActionBtnProps {
  canonicalUrl: ActionBarProps['canonicalUrl'];
}

export default function WhatsappBtn({
  clickAreaStyleExtend,
  iconStyleExtend,
  canonicalUrl,
}: WhatsappBtnProps) {
  const platform = usePlatform();
  const biAction = useBi();
  const url = `${
    platform === 'mobile' ? 'whatsapp://' : 'https://web.whatsapp.com/'
  }send?text=${canonicalUrl}${encodeURIComponent(
    '?utm_source=Web_Share&utm_medium=Whatsapp&utm_campaign=Share'
  )}`;
  const target = platform === 'mobile' ? undefined : '_blank';

  const onClick = () => {
    biAction({
      feature: 'Article Page - Whatsapp Share Button',
      feature_type: 'Content',
      action_id: 11,
    });
  };

  return (
    <ClickArea
      as="a"
      href={url}
      styleExtend={clickAreaStyleExtend}
      rippleSize="small"
      data-testid="whatsapp-btn"
      target={target}
      onClick={onClick}
      title={fork({ default: 'שיתוף בוואטסאפ', hdc: 'Share on Whatsapp' })}
    >
      <Icon icon="whatsapp" styleExtend={iconStyleExtend} variant="neutral" />
      <VisuallyHidden>
        {fork({ default: 'שיתוף בוואטסאפ', hdc: 'Share on Whatsapp' })}
      </VisuallyHidden>
    </ClickArea>
  );
}
