import * as Types from '@haaretz/s-fragments/Types';

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type TagAlertsRegistrationMutationVariables = Types.Exact<{
  input: Types.TagAlertsRegistrationInput;
}>;


export type TagAlertsRegistrationMutation = (
  { tagAlertsRegistration: (
    { status?: string | null }
    & { __typename: 'TagAlertsRegistrationResponse' }
  ) }
  & { __typename: 'Mutation' }
);


export const TagAlertsRegistrationDocument = `
    mutation tagAlertsRegistration($input: TagAlertsRegistrationInput!) {
  tagAlertsRegistration(input: $input) {
    status
  }
}
    `;
export const useTagAlertsRegistrationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<TagAlertsRegistrationMutation, TError, TagAlertsRegistrationMutationVariables, TContext>) =>
    useMutation<TagAlertsRegistrationMutation, TError, TagAlertsRegistrationMutationVariables, TContext>(
      ['tagAlertsRegistration'],
      (variables?: TagAlertsRegistrationMutationVariables) => fetch<TagAlertsRegistrationMutation, TagAlertsRegistrationMutationVariables>(TagAlertsRegistrationDocument, variables)(),
      options
    );
useTagAlertsRegistrationMutation.getKey = () => ['tagAlertsRegistration'];

useTagAlertsRegistrationMutation.fetcher = (variables: TagAlertsRegistrationMutationVariables, options?: RequestInit['headers']) => fetch<TagAlertsRegistrationMutation, TagAlertsRegistrationMutationVariables>(TagAlertsRegistrationDocument, variables, options);