'use client';

import usePlatform from '@haaretz/s-atoms/platform';

export default function FirstImpressionPlaceholder() {
  const platform = usePlatform();

  if (platform === 'app') return null;

  return <div className="FI_PH hide-when-empty" />;
}
