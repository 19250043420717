import * as Types from '@haaretz/s-fragments/Types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type CommentsCountQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CommentsCountQuery = (
  { CommentsElement?: (
    { totalCount?: number | null }
    & { __typename: 'CommentsElement' }
  ) | null }
  & { __typename: 'Query' }
);


export const CommentsCountQueryDocument = `
    query CommentsCountQuery($id: ID!) {
  CommentsElement(id: $id, sort: lastToFirst, cursor: "", count: 0) {
    __typename
    totalCount
  }
}
    `;
export const useCommentsCountQuery = <
      TData = CommentsCountQuery,
      TError = Error
    >(
      variables: CommentsCountQueryVariables,
      options?: UseQueryOptions<CommentsCountQuery, TError, TData>
    ) =>
    useQuery<CommentsCountQuery, TError, TData>(
      ['CommentsCountQuery', variables],
      fetch<CommentsCountQuery, CommentsCountQueryVariables>(CommentsCountQueryDocument, variables),
      options
    );
useCommentsCountQuery.document = CommentsCountQueryDocument;


useCommentsCountQuery.getKey = (variables: CommentsCountQueryVariables) => ['CommentsCountQuery', variables];
;

export const useInfiniteCommentsCountQuery = <
      TData = CommentsCountQuery,
      TError = Error
    >(
      pageParamKey: keyof CommentsCountQueryVariables,
      variables: CommentsCountQueryVariables,
      options?: UseInfiniteQueryOptions<CommentsCountQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CommentsCountQuery, TError, TData>(
      ['CommentsCountQuery.infinite', variables],
      (metaData) => fetch<CommentsCountQuery, CommentsCountQueryVariables>(CommentsCountQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteCommentsCountQuery.getKey = (variables: CommentsCountQueryVariables) => ['CommentsCountQuery.infinite', variables];
;

useCommentsCountQuery.fetcher = (variables: CommentsCountQueryVariables, options?: RequestInit['headers']) => fetch<CommentsCountQuery, CommentsCountQueryVariables>(CommentsCountQueryDocument, variables, options);