import type { RequireOne } from '@haaretz/s-types';

const bpsOrder = ['default', 's', 'm', 'l', 'xl', 'xxl'];
type BP = (typeof bpsOrder)[number];
type BpRangeOpts = RequireOne<{
  from?: BP;
  until?: BP;
}> & { currentBp?: BP };
/**
 * When we're on the server, we don't know the current breakpoint
 * so we explicitly return `undefined` to clarify that the result
 * is ambiguous.
 *
 * For this reason, you should be very careful with doing boolean
 * coercion checks on the value returned from `isInBpRange`.
 */
export default function isInBpRange({ from, until, currentBp }: BpRangeOpts) {
  if (!currentBp) return undefined;
  const currentBpIdx = bpsOrder.indexOf(currentBp);
  if (currentBpIdx < 0) {
    throw new Error(`[isInBpRange] "${currentBp}" isn't a valid breakpoint name`);
  }
  const fromIdx = from != null ? bpsOrder.indexOf(from) : -1;
  const untilIdx = until != null ? bpsOrder.indexOf(until) : -1;
  if (fromIdx < 0 && untilIdx < 0) {
    throw new Error('[isInBpRange] Either "from" or "until" must be a valid breakpoint name');
  }
  let isInRange = undefined;
  if (fromIdx != null && fromIdx >= 0) isInRange = currentBpIdx >= fromIdx;
  if (untilIdx != null && untilIdx >= 0 && isInRange !== false) isInRange = currentBpIdx < untilIdx;
  return isInRange;
}
